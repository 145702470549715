<template>
  <div class="v4-publication-container">
    <div class="container__main-body">
      <div class="main-body__inner">
        <div class="main-body__shade waterfall">
          <c-waterfall
            :max-column="4"
            v-if="lists.length > 0"
            class="water-wrapper"
            @imgLoaded="onImgLoaded"
          >
            <c-waterfall-item
              v-for="(item, index) in lists"
              :key="item.id"
              :data-name="item.name"
              :data-src="item.cover"
              :order="index"
              class="row__item scrollObj"
              @click.native="onJump(item.src)"
            >
              <div class="item__cover water__item-cover">
                <img :src="item.cover" alt="" />
              </div>
              <div class="item__text">
                <template v-if="$bowserMobile">
                  <p>
                    <strong>{{ `${item.name}` }}</strong>
                  </p>
                </template>
                <template v-else>
                  <p>
                    <strong>{{ item.name }}</strong>
                  </p>
                </template>
                <p>{{ item.publish }}</p>
              </div>
            </c-waterfall-item>
          </c-waterfall>
          <template v-if="!waterfallInited">
            <div class="water-load-hint">
              <div class="inner">
                <div class="line"></div>
                <p>图片加载中...</p>
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
    <page-footer></page-footer>
    <el-image-viewer
      v-if="showViewer"
      :on-close="closeViewer"
      :url-list="previewSrcList"
    ></el-image-viewer>
  </div>
</template>

<script>
import ElImageViewer from 'element-ui/packages/image/src/image-viewer.vue';
import { demo2OnScroll } from '@/assets/utils/common';
import { mapState } from 'vuex';
import pageFooter from '../common/page-footer.vue';

export default {
  name: 'v4-page-publication',
  components: {
    pageFooter,
    ElImageViewer,
  },
  filters: {
    qiniuUrl(url) {
      const qiniuCdn = 'http://files.blvd.com.cn/';
      return url.indexOf('http') >= 0 ? url : `${qiniuCdn}${url}`;
    },
  },
  data() {
    return {
      requesting: false,
      page: 1,
      totalPage: 1,
      lists: [],
      waterfallInited: false,
      showViewer: false,
      previewSrcList: [],
      bottomOffset: 300,
    };
  },
  computed: {
    ...mapState(['$bowserMobile']),
    disabled() {
      return this.requesting || this.page > this.totalPage;
    },
    waterfall() {
      const data = [];
      const { lists } = this;
      if (lists.length > 0) {
        const apart = this.$bowserMobile ? 2 : 4;
        for (let i = 0; i < lists.length; i += 1) {
          if (data[i % apart]) {
            data[i % apart].push(lists[i]);
          } else {
            data[i % apart] = [lists[i]];
          }
        }
      }
      return data;
    },
  },
  watch: {
    $route: {
      handler: 'init',
      immediate: true,
    },
  },
  mounted() {
    document.addEventListener('scroll', this.onScroll);
  },
  destroyed() {
    document.removeEventListener('scroll', this.onScroll);
  },
  methods: {
    init() {
      this.$store.commit('setBackgroundColor', '#f0efed');
      document.body.style.cssText = 'background-color:#f0efed;';
      document.body.classList.remove('white-logo');
      this.getData();
    },
    onImgLoaded() {
      this.waterfallInited = true;
      this.$nextTick(() => {
        setTimeout(() => {
          demo2OnScroll();
        }, 500);
      });
    },
    getData() {
      const that = this;
      if (!that.disabled) {
        that.requesting = true;
        that.$fly
          .get('/api/media/publications', {
            page: that.page,
          })
          .then((res) => {
            const { data } = res;
            that.page += 1;
            that.lists.push(...data.list);
            that.totalPage = data._meta.pageCount;
          })
          .finally(() => {
            that.requesting = false;
          });
      }
    },
    onJump(srcs) {
      // const obj = e.currentTarget;
      // window.open(obj.dataset.pdf);
      this.previewSrcList = srcs;
      console.log(srcs);
      this.showViewer = true;
      document.documentElement.style.overflow = 'hidden';
    },
    closeViewer() {
      this.showViewer = false;
      document.documentElement.style.overflow = 'auto';
    },
    onScroll() {
      if (
        document.documentElement.clientHeight
          + document.documentElement.scrollTop
          + this.bottomOffset
        >= document.documentElement.scrollHeight
      ) {
        // console.log('get new');
        this.getData();
      }
    },
  },
};
</script>

<style></style>
